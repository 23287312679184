import React,{useState,useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,dateTimeFormat,currentDateTime,getDateTimeFromISODT} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import _ from 'lodash';
import PrintIcon from '@material-ui/icons/Print';
import Select from 'react-select';
import '../commons/commons.css'


import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import swal from 'sweetalert';
import  InstitutionProfile from '../commons/institution_profile'
import ReactToPrint from "react-to-print";
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const CustomerTransactionHistory = ({location,currentRoute,currentRouteSet,authInfo})=>{
  const classes = useStyles();
  useEffect(()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
  },[]);
  let [workers,workersSet] = useState([]);
  let [transporters,transportersSet] = useState([]);
  let [trailers,trailersSet] = useState([]);
  let [selectedWorker,selectedWorkerSet] = useState(null);
  let [selectedTransporter,selectedTransporterSet] = useState(null);
  let [selectedTrailer,selectedTrailerSet] = useState(null);
  let [report,reportSet] = useState([]);
  let [print,printSet] = useState(false);
  let [reportLoading,reportLoadingSet] = useState(false);


  let [byDateTime,byDateTimeSet] = useState({
        fromDate: currentDateTime,
        toDate:currentDateTime
  })

  let customerRef = useRef(null);
  let fromDateRef = useRef(null);
  let reportRef = useRef(null);


  let [types,typesSet] = useState([{label:'Choose Person Type',value:null},{label:'Worker Wise',value:'worker'},
  {label:'Transporter Wise',value:'transporter'},{label:'Trailer Wise',value:'trailer'}]);
  let [selectedType,selectedTypeSet] = useState({label:'Choose Person Type',value:null});

  useEffect(()=>{ 
    getWorkers();
    getTransporters();
    getTrailers();
  },[]);

  useEffect(()=>{
    reportSet([])
  },[selectedType])

  let getWorkers = async ()=>{
    await axios.post(`${API_URL}/api/get-workers`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          workersSet(res.data)
    })
  }

  let getTransporters = async ()=>{
    await axios.post(`${API_URL}/api/get-transporters`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          transportersSet(res.data)
    })
  }

  let getTrailers = async ()=>{
    await axios.post(`${API_URL}/api/get-trailers`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          trailersSet(res.data)
    })
  }
 
 

  let getSearchResult = async ()=>{


      let payload = {
          workerId:   selectedType.value=='worker' && selectedWorker != null ? selectedWorker.worker_id:null,
          transporterId:   selectedType.value=='transporter' && selectedTransporter != null ? selectedTransporter.transporter_id:null,
          trailerId:   selectedType.value=='trailer' && selectedTrailer != null ? selectedTrailer.trailer_id:null,
          customerType: selectedType != null? selectedType.value:null
      }

      if(selectedType.value==null){
          swal({
              title:'select type',
              icon:'warning'
          })
          return false
      }


      let url = `${API_URL}/api/`
          if(selectedType.value == 'worker'){
            url += `get-worker-due`
          }
          if(selectedType.value == 'trailer'){
            url += `get-trailer-due`
          }
          if(selectedType.value == 'transporter'){
            url += `get-transporter-due`
          }
      
      reportLoadingSet(true)
      await axios.post(url,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
        reportLoadingSet(false)

        reportSet(res.data)
        printSet(true)
  })

  }


  let ReportDom = React.forwardRef((props,ref)=>{
      return(<div ref={ref}>
        <InstitutionProfile />
               {
     report.length>0?(
      <Paper className={classes.paper} style={{marginTop:'8px',marginBottom:'5px'}} >
  
  <p className="print-source" style={{width:'100%',textAlign:'center',fontWeight:'bold',color:'#222'}}>Worker / Transporter / Trailer Due Report </p>
        {
            selectedType != null && selectedType.value == 'worker'?(<>

<TableContainer >
        <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
          <TableHead>
            <TableRow> 
              <TableCell align="left" style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left" style={{width:'10%'}}>Worker Code</TableCell>
              <TableCell align="left" style={{width:'15%'}}>Worker Name</TableCell>
              <TableCell align="right" style={{width:'15%'}}>Mobile No</TableCell>
              <TableCell align="right" style={{width:'15%'}}>Due Amount</TableCell> 
             
            </TableRow>
          </TableHead>
          <TableBody> 
              {
                report.map((row,sl)=>(
                    <TableRow  key=""> 
                    <TableCell>{sl+parseFloat(1)}.</TableCell>
                
                  <TableCell  align="left">{row.worker_code}</TableCell>
                  <TableCell  align="left">{row.worker_name}</TableCell>
                  <TableCell  align="right">{row.worker_mobile}</TableCell>
                  <TableCell  align="right">{format(parseFloat(row.due_amount).toFixed(2))}</TableCell>
                   </TableRow>
                ))
              }
            <TableRow  key=""> 
            <TableCell colSpan={4}  align="right">Total Due : </TableCell>
            <TableCell  align="right">{format(_.sumBy(report,curr => Number(curr.due_amount)).toFixed(2))}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

            
            </>):''
        }



{
            selectedType != null && selectedType.value == 'transporter'?(<>

<TableContainer >
        <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
          <TableHead>
            <TableRow> 
              <TableCell align="left" style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left" style={{width:'10%'}}>Transporter Code</TableCell>
              <TableCell align="left" style={{width:'15%'}}>Transporter Name</TableCell>
              <TableCell align="right" style={{width:'15%'}}>Mobile No</TableCell>
              <TableCell align="right" style={{width:'15%'}}>Due Amount</TableCell> 
             
            </TableRow>
          </TableHead>
          <TableBody> 
              {
                report.map((row,sl)=>(
                    <TableRow  key=""> 
                    <TableCell>{sl+parseFloat(1)}.</TableCell>
                
                  <TableCell  align="left">{row.transporter_code}</TableCell>
                  <TableCell  align="left">{row.transporter_name}</TableCell>
                  <TableCell  align="right">{row.transporter_mobile}</TableCell>
                  <TableCell  align="right">{format(parseFloat(row.due_amount).toFixed(2))}</TableCell>
                   </TableRow>
                ))
              }
            <TableRow  key=""> 
            <TableCell colSpan={4}  align="right">Total Due : </TableCell>
            <TableCell  align="right">{format(_.sumBy(report,curr => Number(curr.due_amount)).toFixed(2))}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

            
            </>):''
        }

{
            selectedType != null && selectedType.value == 'trailer'?(<>

<TableContainer >
        <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
          <TableHead>
            <TableRow> 
              <TableCell align="left" style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left" style={{width:'10%'}}>Trailer Code</TableCell>
              <TableCell align="left" style={{width:'15%'}}>Trailer Name</TableCell>
              <TableCell align="right" style={{width:'15%'}}>Mobile No</TableCell>
              <TableCell align="right" style={{width:'15%'}}>Due Amount</TableCell> 
             
            </TableRow>
          </TableHead>
          <TableBody> 
              {
                report.map((row,sl)=>(
                    <TableRow  key=""> 
                    <TableCell>{sl+parseFloat(1)}.</TableCell>
                
                  <TableCell  align="left">{row.trailer_code}</TableCell>
                  <TableCell  align="left">{row.trailer_name}</TableCell>
                  <TableCell  align="right">{row.trailer_mobile}</TableCell>
                  <TableCell  align="right">{format(parseFloat(row.due_amount).toFixed(2))}</TableCell>
                   </TableRow>
                ))
              }
            <TableRow  key=""> 
            <TableCell colSpan={4}  align="right">Total Due : </TableCell>
            <TableCell  align="right">{format(_.sumBy(report,curr => Number(curr.due_amount)).toFixed(2))}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

            
            </>):''
        }
     

          </Paper>
     ):''
  }
      </div>)
  })

    return(
        <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
  marginBottom: '2px'}}>Worker / Transporter / Trailer Due Report</h4> 
  
  

<Grid container spacing={3} > 
        <Grid item  xs={12}   sm={2} > 
                <Select
                value={selectedType}
                onChange={(type)=>{
                    if(type == null){
                        return false;
                    }
                    selectedTypeSet(type);
                }}
                options={types}

            />
        </Grid>
        {
          selectedType != null && selectedType.value =='worker' ? (
            <Grid item  xs={12}   sm={2} > 
            <Autocomplete 
            size="small"
    
            autoHighlight
                openOnFocus={true}
                style={{width:'100%',height:'20px'}}
                options={workers}
                value={selectedWorker}
                getOptionLabel={(option) => option.worker_name}
                onChange={(event,selectedObj)=>{
                   
                    selectedWorkerSet(selectedObj)
                   
                }}
               
    
                renderInput={(params) => <TextField 
                   
                  {...params} 
                  label="Worker " 
                  variant="outlined"
                  />} 
            />
            </Grid>
          ):''
        }


{
          selectedType != null && selectedType.value =='transporter' ? (
            <Grid item  xs={12}   sm={2} > 
            <Autocomplete 
            size="small"
    
            autoHighlight
                openOnFocus={true}
                style={{width:'100%',height:'20px'}}
                options={transporters}
                value={selectedTransporter}
                getOptionLabel={(option) => option.transporter_name}
                onChange={(event,selectedObj)=>{
                   
                    selectedTransporterSet(selectedObj)
                   
                }}
               
    
                renderInput={(params) => <TextField 
                   
                  {...params} 
                  label="Transporter " 
                  variant="outlined"
                  />} 
            />
            </Grid>
          ):''
        }
       
       {
          selectedType != null && selectedType.value =='trailer' ? (
            <Grid item  xs={12}   sm={2} > 
            <Autocomplete 
            size="small"
    
            autoHighlight
                openOnFocus={true}
                style={{width:'100%',height:'20px'}}
                options={trailers}
                value={selectedTrailer}
                getOptionLabel={(option) => option.trailer_name}
                onChange={(event,selectedObj)=>{
                   
                    selectedTrailerSet(selectedObj)
                   
                }}
               
    
                renderInput={(params) => <TextField 
                   
                  {...params} 
                  label="Trailer " 
                  variant="outlined"
                  />} 
            />
            </Grid>
          ):''
        }

        
        
       
        <Grid item  xs={12}   sm={1} >
      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SearchIcon/>}
                          onClick={getSearchResult}
                          disabled={reportLoading?true:false}
                      >
                     Search 
                    </Button>
      </Grid>

      </Grid> 
      </Paper> 

      {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-7px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }

            <ReportDom ref={reportRef} />
</div>

    )
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(CustomerTransactionHistory);