import React,{Fragment, useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,dateFormat,currentDateTime} from '../../lib/functions'
import {currentRouteSet,createdCashTranSet,updatedCashTranSet,cashTranDisableRestoreSet,cashTranCodeSet} from '../../actions/actions';
import moment from 'moment';


const CashTransManage = ({location,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    
    let [action,actionSet] = useState('create')
    let [tran_note,tran_note_set] = useState('')
    let [tran_amount,tran_amount_set] = useState('')
    let [tran_id,tran_id_set] = useState(0)
    let [trans,trans_set] = useState([])


    let [workers,workersSet] = useState([])
    let [transporters,transportersSet] = useState([])
    let [trailers,trailersSet] = useState([])
    let [bankAccs,bankAccsSet] = useState([])

    let [selectedWorker,selectedWorkerSet] = useState(null)
    let [selectedTransporter,selectedTransporterSet] = useState(null)
    let [selectedTrailer,selectedTrailerSet] = useState(null)
    let [selectedBankAcc,selectedBankAccSet] = useState(null)
    
    let payMethods = [{pay_method:'cash'},
    {pay_method:'bank'}];

    let [selectedPayMethod,selectedPayMethodSet] = useState(null)



    let [loadingSave,loadingSaveSet] = useState(false)
    let [loadingList,loadingListSet] = useState(false)
     
    let [tranCode,tranCodeSet] = useState('')
    let [selectedTranType,selectedTranTypeSet] = useState({tran_type:'payment'})
    let [selectedPersonType,selectedPersonTypeSet] = useState(null)

    let [tran_acc_name,tran_acc_name_set] = useState('')
    let tranTypes = [{tran_type:'receive'},{tran_type:'payment'}]
    let personTypes = [{tran_person_type:'worker'},{tran_person_type:'transporter'},{tran_person_type:'trailer'}]
    
    
    const [selectedDate, handleDateChange] = useState(currentDateTime);

    let cashTranTypeRef = React.useRef(null)
    let cashTranAccRef = React.useRef(null)
    let cashTranDateRef = React.useRef(null)
    let cashTranNoteRef = React.useRef(null)
    let cashTranAmountRef = React.useRef(null) 
    let cashTranActionRef = React.useRef(null) 
    const [successMsg, setSuccessMsg] = useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
      msg:''
    });
    const { vertical, horizontal, open,msg } = successMsg;
    const handleClose = () => {
      setSuccessMsg({ ...successMsg, open: false });
    };

    useEffect(()=>{
       currentRouteSet(pathSpliter(location.pathname,1));
        getTransporters()
        getworkers()
        getTrailers()
        // getBankAccs()
        getTwtrTrans()
        getLoadIntoAccounts()
    },[]);

    let getTwtrTrans = async ()=>{
        await axios.post(`${API_URL}/api/get-w-t-tr-trans`,{"select-type": "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
          trans_set(res.data) 
        })
    }
   
//     let getBankAccs = async ()=>{
//         await axios.post(`${API_URL}/api/get-bank-accounts`,{"select-type": "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
//           bankAccsSet(res.data.message)
//         })
//   }
    let getTransporters = async ()=>{
          await axios.post(`${API_URL}/api/get-transporters`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            transportersSet(res.data)
          })
    }

    let getworkers = async()=>{
        await axios.post(`${API_URL}/api/get-workers`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
         workersSet(res.data)
        })
  }

  let getTrailers = async ()=>{
    await axios.post(`${API_URL}/api/get-trailers`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
     trailersSet(res.data)
    })
}




    
  

const getLoadIntoAccounts = async() => {
    await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:'',search:false,multiType:[
      'cash_in_hand','bank_account'
    ]},{headers:{'auth-token':authInfo.token}}).then(res=>{
        bankAccsSet(res.data)
    })
};
    

  
    const saveFormAction = async ()=>{   



      if(selectedTranType==null){  
        swal({
          title:'Select a  transaction type',
          icon:'warning'
        })
      }else if(selectedPersonType==null){  
        swal({
          title:'Select a  person type',
          icon:'warning'
        })
      }else if(selectedPersonType.tran_person_type == 'worker' && selectedWorker==null){  
        swal({
          title:'Select a worker',
          icon:'warning'
        })
      }else if(selectedPersonType.tran_person_type == 'transporter' && selectedTransporter==null){  
        swal({
          title:'Select a Transporter',
          icon:'warning'
        })
      }else if(selectedPersonType.tran_person_type == 'trailer' && selectedTrailer==null){  
        swal({
          title:'Select a Trailer',
          icon:'warning'
        })
      }else if(selectedBankAcc == null){  
        swal({
          title:'Select a    Account', 
          icon:'warning'
        })
      }else if(tran_amount=='' || tran_amount == 0){  
        swal({
          title:'Amount is Required.', 
          icon:'warning'
        });
        return false
      }else{   
           
            let payLoad = {
              action,
              tran_id,
              tran_isodt : selectedDate,
              tran_type :  selectedTranType.tran_type,
              tran_person_type : selectedPersonType.tran_person_type,
              
              tran_worker_id : selectedPersonType.tran_person_type=='worker' ? selectedWorker.worker_id :0,
              tran_transporter_id : selectedPersonType.tran_person_type=='transporter' ? selectedTransporter.transporter_id :0,
              tran_trailer_id : selectedPersonType.tran_person_type=='trailer' ? selectedTrailer.trailer_id :0,

              tran_method: 'cash',
              tran_bank_id:  selectedBankAcc.acc_id ,
              tran_note,
              tran_amount: tran_amount==''?0:tran_amount,
             
            }


           
            
         loadingSaveSet(true)
            await axios.post(`${API_URL}/api/w_t_tr_transaction-cu`,payLoad,{headers:{'auth-token':authInfo.token}}).then(res=>{
            loadingSaveSet(false)
            


            actionSet('create');
            selectedTranTypeSet({tran_type:'payment'});
            selectedPersonTypeSet(null);
  
            selectedWorkerSet(null)
            
            selectedTrailerSet(null)
            selectedTransporterSet(null)

            selectedPayMethodSet(null)
  
            selectedBankAccSet(null)
            
  
            tran_id_set(0)
            tran_note_set('')
            tran_amount_set(0)
  
            handleDateChange(currentDateTime)




             swal({
               title:`${res.data.message}`,
               icon:'success'
             })
             getTwtrTrans()

             
            
            
            })
         }
    }

    const tranEdit = (row,index)=>{
          actionSet('update');
          selectedTranTypeSet({tran_type:trans[index].tran_type});
          selectedPersonTypeSet({tran_person_type:trans[index].tran_person_type});

          if(trans[index].tran_person_type == 'worker'){
            selectedWorkerSet({worker_id:trans[index].tran_worker_id,worker_name:trans[index].worker_name})
          }

          if(trans[index].tran_person_type == 'trailer'){
            selectedTrailerSet({trailer_id:trans[index].tran_trailer_id,trailer_name:trans[index].trailer_name})
          }

          if(trans[index].tran_person_type == 'transporter'){
            selectedTransporterSet({transporter_id:trans[index].tran_transporter_id,transporter_name:trans[index].transporter_name})
          } 

        //   selectedPayMethodSet({pay_method :trans[index].tran_method})

        //   if(trans[index].tran_method == 'bank'){
            selectedBankAccSet({acc_id:trans[index].tran_bank_id,acc_name : trans[index].acc_name})
        //   }

          tran_id_set(trans[index].tran_id)
          tran_note_set(trans[index].tran_note)
          tran_amount_set(trans[index].tran_amount)

          handleDateChange(trans[index].tran_isodt)
 

    }
    const cusomerTranDelete = async (tranId)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async next=>{
        if(next){
          await axios.post(`${API_URL}/api/transaction-delete`,{tranId:tranId},{headers:{'auth-token':authInfo.token}}).then(res=>{
          
            swal({
                title:res.data.message,
                icon:'success'
              })
              getTwtrTrans()

          })
        }else{
          return false
        }
      })
      
    }

    
    const ActionOptions = (props)=>{
        return(<div style={{textAlign:'right'}}>
            {
    authInfo.role !='user'?(
    <>
          <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>tranEdit(props.rowData,props.rowIndex)}/>
         
                <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>cusomerTranDelete(props.rowData[0])}/>
           
   </>):''
 }
        
        </div>)
      
      }
      
    const columns = [
        {name: "tran_id",options: { display: 'excluded' }},
        {name: "tran_isodt",options: { display: 'excluded' }},
        {name:"SL",options: {filter: false,sort: false,
          customBodyRender:(value,tableMeta)=>{
          return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
        }
        },headerStyle: {
          textAlign:'left'
        }},
        {name: "tran_code",label: "Tran code",options: {filter: true,sort: true}},
        {name: "tran_type",label: "Tran Type",options: {filter: true,sort: true}},
        {name: "acc_name",label: " Account Name ",options: {filter: true,sort: true}},
        {name: "tran_person_type",label: "Tran Person Type",options: {filter: true,sort: true}},
        {name: "tran_person",label: "Tran Person",options: {filter: true,sort: true}},

        {name: "tran_isodt",label: "Entry Date & Time",options: {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(<p>{  moment(tableMeta.rowData[1]).format(dateFormat) }</p>) 
          }
        }
        
      },
      {name: "tran_amount",label: "Amount",options: {filter: true,sort: true}},

     
     
        {name:"actions",options: {filter: false,sort: false,
          customBodyRender:(value,tableMeta)=>{
            return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
               /> ); 
        }
        },headerStyle: {
          textAlign:'right'
        }}
       ];
              
       const options = {
         filterType: 'checkbox',
         selectableRows: 'none',
         display: "excluded"
        }

    return (
        <div className={classes.root}>
             {/* Success message */}
              <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  {successMsg.msg}
                </Alert>
              </Snackbar>  
              {/* End of message */}
            <Paper className={classes.paper} style={{marginTop:'-15px'}}> 
            <h2 className={classes.pageEntryLabel}> Worker, Transporter & Trailer Transaction Entry</h2> 

            <Grid container spacing={2}> 
            {/* <Grid item xs={12} sm={3}> 
            <TextField  autoComplete='off'  className={classes.fullWidth}  value={tranCode} 
            name="tranCode" style={{color:'#222'}} disabled variant="outlined" size="small" 

            />
            </Grid> */}

            <Grid item xs={12} sm={3}  className={classes.plusLinkDiv}>
                  
                <Autocomplete
                style={{ width: '100%' }}
                options={tranTypes}
                size="small"
                classes={{
                    option: classes.option
                }}
                autoHighlight={true}
                openOnFocus={true}
                getOptionLabel={(option) => option.tran_type}
                value={selectedTranType} 
                onChange={(event,selectedObj)=>{
                  selectedTranTypeSet(selectedObj)
                }}
                // loading={cashTranTypes.length==0?true:false}
                renderInput={(params) => (
                    <TextField
                    inputRef={cashTranTypeRef}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            cashTranAccRef.current.focus()
                        }
                      }}
                    {...params}
                    label="Choose a transaction type"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {cashTranAccs.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment} */}
                          </React.Fragment>
                        ),
                    }}
                    />
                )}
                /> 
            </Grid>

            <Grid item xs={12} sm={3}  className={classes.plusLinkDiv}>
                  
                <Autocomplete
                style={{ width: '100%' }}
                options={personTypes}
                size="small"
                classes={{
                    option: classes.option
                }}
                autoHighlight={true}
                openOnFocus={true}
                getOptionLabel={(option) => option.tran_person_type}
                value={selectedPersonType} 
                onChange={(event,selectedObj)=>{
                    selectedPersonTypeSet(selectedObj)  
                }}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label="Choose a transaction person"
                    variant="outlined"
                    />
                )}
                /> 
            </Grid>

              {
                selectedPersonType != null && selectedPersonType.tran_person_type =='worker'?(<>
                    <Grid item xs={12} sm={3}  className={classes.plusLinkDiv}>
                  
                  <Autocomplete
                  style={{ width: '100%' }}
                  options={workers}
                  size="small"
                  classes={{
                      option: classes.option
                  }}
                  autoHighlight={true}
                  openOnFocus={true}
                  getOptionLabel={(option) => option.worker_name}
                  value={selectedWorker} 
                  onChange={(event,selectedObj)=>{
                    selectedWorkerSet(selectedObj)  
                  }}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      label="Choose a transaction worker"
                      variant="outlined"
                      />
                  )}
                  /> 
              </Grid>
                </>):''
              }
          

              {
                selectedPersonType != null && selectedPersonType.tran_person_type =='transporter'?(<>
                     <Grid item xs={12} sm={3}  className={classes.plusLinkDiv}>
                  
                  <Autocomplete
                  style={{ width: '100%' }}
                  options={transporters}
                  size="small"
                  classes={{
                      option: classes.option
                  }}
                  autoHighlight={true}
                  openOnFocus={true}
                  getOptionLabel={(option) => option.transporter_name}
                  value={selectedTransporter} 
                  onChange={(event,selectedObj)=>{
                    selectedTransporterSet(selectedObj)  
                  }}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      label="Choose a transaction transporter"
                      variant="outlined"
                      />
                  )}
                  /> 
              </Grid>

                </>):''
              }
           
              {
                selectedPersonType != null && selectedPersonType.tran_person_type =='trailer'?(<>
                    <Grid item xs={12} sm={3}  className={classes.plusLinkDiv}>
                  
                  <Autocomplete
                  style={{ width: '100%' }}
                  options={trailers}
                  size="small"
                  classes={{
                      option: classes.option
                  }}
                  autoHighlight={true}
                  openOnFocus={true}
                  getOptionLabel={(option) => option.trailer_name}
                  value={selectedTrailer} 
                  onChange={(event,selectedObj)=>{
                    selectedTrailerSet(selectedObj)  
                  }}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      label="Choose a transaction trailer"
                      variant="outlined"
                      />
                  )}
                  /> 
              </Grid>
                </>):''
              }
            



           


            <Grid item xs={12} sm={3}  className={classes.plusLinkDiv} style={{display:'none'}}>
                <Autocomplete
                style={{ width: '100%' }}
                options={payMethods}
                size="small"
                classes={{
                    option: classes.option,
                }}
               
                openOnFocus={true}
                getOptionLabel={(option) =>option.pay_method}
                value={selectedPayMethod} 
                onChange={(event,selectedObj)=>{
                  selectedPayMethodSet(selectedObj)
                }}
                autoHighlight={true}
                // loading={cashTranAccs.length==0?true:false}
                renderInput={(params) => (
                    <TextField
                    inputRef={cashTranAccRef}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                          cashTranDateRef.current.focus()
                        }
                      }}
                    {...params}
                    label="Choose a Pay Method"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {cashTranAccs.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment} */}
                          </React.Fragment>
                        ),
                        
                    }}
                    />
                )}
                /> 
            </Grid>
                   
                         <Grid item xs={12} sm={3}  className={classes.plusLinkDiv}>
                <Autocomplete
                style={{ width: '100%' }}
                options={bankAccs}
                size="small"
                classes={{
                    option: classes.option,
                }}
               
                openOnFocus={true}
                getOptionLabel={(option) =>option.acc_name}
                value={selectedBankAcc} 
                onChange={(event,selectedObj)=>{
                  selectedBankAccSet(selectedObj)
                }}
                autoHighlight={true}
                // loading={cashTranAccs.length==0?true:false}
                renderInput={(params) => (
                    <TextField
                    inputRef={cashTranAccRef}
                  
                    {...params}
                    label="Choose a  account"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {cashTranAccs.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment} */}
                          </React.Fragment>
                        ),
                        
                    }}
                    />
                )}
                /> 
            </Grid>

                

         


           

            
            <Grid item xs={12} sm={3}  > 
            <MuiPickersUtilsProvider  utils={DateFnsUtils}>
          
            <KeyboardDateTimePicker
            style={{ width: '100%',marginTop: '-8px' }}
             inputRef={cashTranDateRef}
             onKeyDown={event => {
                 if (event.key === "Enter") {
                   cashTranNoteRef.current.focus()
                 }
               }}
        value={selectedDate}
        onChange={handleDateChange}
        label="Entry date  time"
        format="yyyy/MM/dd hh:mm a"
      />
    </MuiPickersUtilsProvider>
</Grid>
            <Grid item xs={12} sm={3}  > 
            <TextField  autoComplete='off'  className={classes.fullWidth}  value={tran_note} 
            label="Transaction note" name="tran_note" style={{color:'#222'}}  variant="outlined" size="small"  onChange={(e)=>tran_note_set(e.target.value)} 
            inputRef={cashTranNoteRef}
            onKeyDown={event => {
                if (event.key === "Enter") {
                  cashTranAmountRef.current.focus()
                }
              }}
            />
            </Grid>
            <Grid item xs={12} sm={3}  > 
            <TextField  type="number" autoComplete='off'  className={classes.fullWidth}  value={tran_amount} 
            label="Transaction amount" name="tran_amount" style={{color:'#222'}}  variant="outlined" size="small"  onChange={(e)=>tran_amount_set(e.target.value)} 
            inputRef={cashTranAmountRef}
            onKeyDown={event => {
                if (event.key === "Enter") {
                  cashTranActionRef.current.click()
                }
              }}
            />
            </Grid>
           
           </Grid>
           
           <Grid item xs={12}>
          <Button style={{marginTop: '25px'}}
            variant="contained"
            color="primary"
            size="small"
            buttonRef={cashTranActionRef}
            className={classes.button}
            startIcon={<SaveIcon/>}
            disabled={loadingSave}
            onClick={saveFormAction}
        >
        Save
      </Button>
  </Grid>
            </Paper>
            {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Worker, Transporter & Trailer Transaction List"}
      data={trans}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }



    


        </div>
    )
}



const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  root: {},
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch',
   },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pageEntryLabel:{
        color: '#7754cc',
        margin: '0px',
        padding: '0px',
        marginTop: '-11px',
        textAlign: 'left',
        marginLeft: '0px',
        marginBottom: '5px',
        textAlign:'left'
    },
    fullWidth:{
        width:'100%'
    },
    option: {
        fontSize: 15,
        '& > span': {
          marginRight: 10,
          fontSize: 18,
        },
      },
      plusLinkDiv:{
            position:'relative'  
      },
      plusLink:{
        margin: 0,
        padding: 0,
        marginTop: '-21px',
        fontSize: '29px',
        height: '21px',
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        color: '#3e8d54'
      }
  }));
  
  const mapStateToPops = (state)=>{
        return {
          currentRoute:state.currentRouteReducer,
          authInfo:state.authInfoReducer,
         
        }
  }
  export default connect(mapStateToPops,{currentRouteSet})(CashTransManage);